import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [downloadMenu, setDownloadMenu] = useState(false);

  useEffect(() => {
    if (mobileMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [mobileMenu]);

  return (
    <nav>
      <section className="xl:px-[13rem] lg:px-[3rem] px-[1.5rem] flex items-center justify-between">
        <Link to={"/"}>
          <img src="/images/logo.svg" alt="logo" className="w-[150px]" />
        </Link>
        <div className="sm:flex md:gap-[1.5rem] gap-[1rem]  hidden">
          <Link
            to={"/"}
            className="text-primary text-center inter text-[18px] font-normal leading-[30px] hover:cursor-pointer hover:underline transition ease-in duration-700"
            href="#menu"
          >
            Home
          </Link>

          <a
            className="text-primary text-center inter text-[18px] font-normal leading-[30px] hover:cursor-pointer hover:underline transition ease-in duration-700"
            href="#contact"
          >
            Contact Us
          </a>

          <p
            className="text-primary text-center inter text-[18px] font-normal leading-[30px] hover:cursor-pointer hover:underline transition ease-in duration-700"
            onClick={() => setDownloadMenu(true)}
          >
            Get the App
          </p>
        </div>
        <img
          src="/images/menu-icon.svg"
          alt=""
          className="w-[40px] h-[40px] cursor-pointer sm:hidden flex"
          onClick={() => setMobileMenu(true)}
        />
      </section>
      <div className="bg-line h-[1px]  marginP flex items-center justify-center "></div>

      {mobileMenu && (
        <div className="mobile__menu__container ">
          <img
            src="/images/close.svg"
            alt=""
            className="w-[40px] h-[40px] cursor-pointer right-[20px] top-[30px]  absolute"
            onClick={() => setMobileMenu(false)}
          />
          <nav className="mt-[13px] flex items-center  flex-col justify-center gap-[43px] pt-[6rem]">
            <Link
              to={"/"}
              className="text-primary text-center inter text-[18px] font-normal leading-[30px] hover:cursor-pointer hover:underline transition ease-in duration-700"
              href="#menu"
              onClick={() => setMobileMenu(false)}
            >
              Home
            </Link>

            <a
              className="text-primary text-center inter text-[18px] font-normal leading-[30px] hover:cursor-pointer hover:underline transition ease-in duration-700"
              href="#contact"
              onClick={() => setMobileMenu(false)}
            >
              Contact Us
            </a>

            <p
              className="text-primary text-center inter text-[18px] font-normal leading-[30px] hover:cursor-pointer hover:underline transition ease-in duration-700"
              onClick={() => {
                setMobileMenu(false);
                setDownloadMenu(true);
              }}
            >
              Get the App
            </p>
          </nav>
        </div>
      )}

      {downloadMenu && (
        <section className="downloadMenu">
          <div className="downloadPopup relative px-5 sm:flex-row flex-col flex gap-[2rem]">
            <img
              src="/mobile.png"
              alt="mobile-img"
              className="lg:mt-[60px] mt-[20px] lg:w-[343px] w-[200px] md:ml-[40px] ml-[20px]"
            />
            <div className="md:mt-[90px] sm:mt-[70px] mt-[0px] max-w-[470px]">
              <h2 className="text-white italic lg:text-[67px] sm:text-[40px] text-[20px] sm:leading-[50px] leading-[25px] italic lg:leading-[69px] volkhov">
                Download the Starbites App <br className="lg:flex hidden" />
                Now!
              </h2>
              <div className="flex mt-[30px] flex-wrap gap-[1rem]">
                <Link
                  to={"https://apps.apple.com/gh/app/starbites/id1573083411"}
                  target="_blank"
                >
                  <img
                    src="/images/appstore.svg"
                    alt="appstore__button"
                    className="cursor-pointer sm:w-[162px] w-[150px]"
                  />
                </Link>
                <Link
                  to={
                    "https://play.google.com/store/apps/details?id=com.starbiteapp&hl=en_US"
                  }
                  target="_blank"
                >
                  <img
                    src="/images/googleplay.svg"
                    alt="appstore__button"
                    className="cursor-pointer sm:w-[182px] w-[150px]"
                  />
                </Link>
              </div>
            </div>
            <img
              src="/images/close.svg"
              alt="close-icon"
              className="absolute xs:right-[4rem] right-[10px] xs:mt-[2rem] mt-[1rem] cursor-pointer xs:w-[32px] w-[25px] xs:h-[32px] h-[25px]"
              onClick={() => setDownloadMenu(false)}
            />
          </div>
        </section>
      )}
    </nav>
  );
};

export default Navbar;
