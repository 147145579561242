import React from "react";
import AccordionItem from "./AccordionItem";
import { Api } from "../api";

const Menu = () => {
  const [categories, setCategories] = React.useState([]);
  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    Api.get(
      `/stores/merchant/${"MER03453"}/store/outlet/${"1892"}/product/category`
    ).then((res) => {
      setCategories(res?.data?.data);
    });
  }, []);

  React.useEffect(() => {
    Api.get(
      `/stores/merchant/${"MER03453"}/store/outlet/${"1892"}/products`
    ).then((res) => {
      setProducts(res?.data?.data);
    });
  }, []);

  return (
    <section id="menu" className="sm:mb-[4rem] mb-[2rem]">
      <h2 className="text-deepRed italic text-center font-bold volkhov md:text-[46px] sm:text-[35px] text-[25px] md:mt-[70px] mt-[50px] ">
        Our Menu
      </h2>

      <div className="flex items-center flex-col justify-center gap-[30px] md:mt-[100px] mt-[50px]">
        <img src="/images/menu-logo.svg" alt="menu_logo" />
        <p className="max-w-[550px] text-center text-warning sm:leading-[28px] leading-[20px] sm:text-[18px] text-[16px] mb-[38px] px-[1rem]">
          from full breakfast to a wide range of carefully selected lunch &
          dinner meal options fused with some of the popular local cuisine.
        </p>
      </div>

      <div className="flex items-center gap-[2rem]">
        <img
          src="/images/flower.svg"
          alt="flower__image"
          className="mb-[-120px] md:w-[200px] w-[150px]  sm:flex hidden"
        />

        <div className="flex lg:flex-row lg:px-0 sm:px-[2rem] flex-col gap-[3rem] px-[1rem]">
          <div className="xl:ml-[150px] lg:ml-[50px] ml-0">
            <div className="grid xs:grid-cols-2 gap-12 justify-between h-[65vh] overflow-auto scroll__bar pr-[1.5rem]">
              {categories?.map((cat) => {
                const categoryProducts = products.filter(
                  (product) => product?.product_category === cat?.category_name
                );
                return (
                  <div key={cat?.category_id}>
                    <div className="flex gap-[12px] mb-[32px]">
                      <p className="text-lightGreen text-[24px] italic volkhov mt-[10px]">
                        {cat?.category_name}
                      </p>
                    </div>
                    {categoryProducts?.map((prod) => {
                      return (
                        <AccordionItem
                          key={prod?.product_id}
                          titleColor={"lightGreen"}
                          priceColor={"lightRed_100"}
                          title={prod?.product_name}
                          price={
                            "GHS " +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }).format(prod?.product_price)
                          }
                          content={prod?.product_description}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>

          <img
            src="/images/menu-img.svg"
            alt=""
            className="xl:w-[600px] lg:w-[400px] sm:w-[500px] w-full lg:h-[70vh] h-full"
          />
        </div>
      </div>
    </section>
  );
};

export default Menu;
