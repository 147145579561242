import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { Link as LinkHref } from "react-router-dom";
const Hero = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [downloadMenu, setDownloadMenu] = useState(true);
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    const headerBackgrounds = document.querySelectorAll(".background");

    const changeBackground = () => {
      headerBackgrounds[imageIndex].classList.remove("showing");

      let newIndex = (imageIndex + 1) % headerBackgrounds.length;
      setImageIndex(newIndex);

      headerBackgrounds[newIndex].classList.add("showing");
    };

    const intervalId = setInterval(changeBackground, 3000);

    return () => clearInterval(intervalId);
  }, [imageIndex]);

  return (
    <header className="overlayBg">
      <div className="background-container">
        <img src="/images/bg.png" alt="bg" className="showing background" />
        <img src="/images/bg1.png" alt="bg" className="background" />
        <img src="/images/bg2.png" alt="bg" className="background" />
        <img src="/images/bg3.png" alt="bg" className="background" />
      </div>
      <div className="hidden sm:block">
        <div className="flex items-center justify-center mt-[70px] w-full">
          {/* Logo */}
          <img
            src="/images/logo.png"
            alt="logo"
            className="w-[226px] h-[129px] contain"
          />
        </div>

        {/* Nav Links */}

        <nav className="mt-[13px] flex items-center justify-center gap-[43px]">
          <Link
            activeClass="active"
            to="menu"
            spy={true}
            smooth={true}
            offset={-30}
            duration={500}
            className="text-primary text-center inter text-[18px] font-normal leading-[30px] hover:cursor-pointer hover:underline transition ease-in duration-700"
          >
            Menu
          </Link>
          <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={-30}
            duration={500}
            className="text-primary text-center inter text-[18px] font-normal leading-[30px] hover:cursor-pointer hover:underline transition ease-in duration-700"
          >
            About Us
          </Link>
          <Link
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={-30}
            duration={500}
            className="text-primary text-center inter text-[18px] font-normal leading-[30px] hover:cursor-pointer hover:underline transition ease-in duration-700"
          >
            Contact Us
          </Link>
          <a
            className="text-primary text-center inter text-[18px] font-normal leading-[30px] hover:cursor-pointer hover:underline transition ease-in duration-700"
            href="#get"
            onClick={() => setDownloadMenu(true)}
          >
            Get the App
          </a>
        </nav>

        <div className="bg-line h-[1px]  margin flex items-center justify-center "></div>
      </div>

      {/* Mobile Hero */}

      <div className="sm:hidden block px-3 navbar-mobile">
        <div className="flex items-center justify-between w-full">
          {/* Logo */}
          <img
            src="/images/logo.png"
            alt="logo"
            className="w-[150px]  contain cursor-pointer"
          />

          {mobileMenu && (
            <div className="mobile__menu__container">
              <img
                src="/images/close.svg"
                alt=""
                className="w-[40px] h-[40px] cursor-pointer right-[20px] top-[30px]  absolute"
                onClick={() => setMobileMenu(false)}
              />
              <nav className="mt-[13px] flex items-center  flex-col justify-center gap-[43px] pt-[6rem]">
                <a
                  className="text-primary text-center inter text-[18px] font-normal leading-[30px] hover:cursor-pointer hover:underline transition ease-in duration-700"
                  href="#menu"
                  onClick={() => setMobileMenu(false)}
                >
                  Menu
                </a>
                <a
                  className="text-primary text-center inter text-[18px] font-normal leading-[30px] hover:cursor-pointer hover:underline transition ease-in duration-700"
                  href="#about"
                  onClick={() => setMobileMenu(false)}
                >
                  About Us
                </a>
                <a
                  className="text-primary text-center inter text-[18px] font-normal leading-[30px] hover:cursor-pointer hover:underline transition ease-in duration-700"
                  href="#contact"
                  onClick={() => setMobileMenu(false)}
                >
                  Contact Us
                </a>
                <a
                  className="text-primary text-center inter text-[18px] font-normal leading-[30px] hover:cursor-pointer hover:underline transition ease-in duration-700"
                  href="#blog"
                  onClick={() => setMobileMenu(false)}
                >
                  Blog
                </a>
                <a
                  className="text-primary text-center inter text-[18px] font-normal leading-[30px] hover:cursor-pointer hover:underline transition ease-in duration-700"
                  href="#get"
                  onClick={() => {
                    setMobileMenu(false);
                    setDownloadMenu(true);
                  }}
                >
                  Get the App
                </a>
              </nav>
            </div>
          )}

          <img
            src="/images/menu-icon.svg"
            alt=""
            className="w-[40px] h-[40px] cursor-pointer"
            onClick={() => setMobileMenu(true)}
          />
        </div>

        {/* Nav Links */}

        <div className="bg-line h-[1px] sm:block hidden  margin flex items-center justify-center "></div>
      </div>

      <div className="mt-[8rem] flex justify-center flex-col sm:gap-[60px] gap-[40px]">
        <h1 className="text-center volkhov md:text-[76px] sm:text-[50px] text-[35px] italic font-bold text-lightRed md:leading-[90px] leading-[60px] px-[20px]">
          Come For The Food, Stay <br className="hidden sm:flex" />
          For The Experience.
        </h1>
      </div>

      {downloadMenu && (
        <section className="downloadMenu">
          <div className="downloadPopup relative px-5 sm:flex-row flex-col flex gap-[2rem] pb-[3rem]">
            <img
              src="/mobile.png"
              alt="mobile-img"
              className="lg:mt-[60px] sm:mt-[20px] mt-[50px] lg:w-[343px] w-[200px] md:ml-[40px] sm:ml-[20px]"
            />
            <div className="md:mt-[90px] sm:mt-[70px] mt-[0px] max-w-[470px]">
              <h2 className="text-white italic lg:text-[67px] sm:text-[40px] text-[20px] sm:leading-[50px] leading-[25px] italic lg:leading-[69px] volkhov">
                Download the Starbites App <br className="lg:flex hidden" />
                Now!
              </h2>
              <div className="flex mt-[30px] flex-wrap gap-[1rem]">
                <LinkHref
                  to={"https://apps.apple.com/gh/app/starbites/id1573083411"}
                  target="_blank"
                >
                  <img
                    src="/images/appstore.svg"
                    alt="appstore__button"
                    className="cursor-pointer sm:w-[162px] w-[150px]"
                  />
                </LinkHref>
                <LinkHref
                  to={
                    "https://play.google.com/store/apps/details?id=com.starbiteapp&hl=en_US"
                  }
                  target="_blank"
                >
                  <img
                    src="/images/googleplay.svg"
                    alt="appstore__button"
                    className="cursor-pointer sm:w-[182px] w-[150px]"
                  />
                </LinkHref>
              </div>
            </div>
            <img
              src="/images/close.svg"
              alt="close-icon"
              className="absolute sm:right-[3rem] right-[10px] sm:mt-[2rem] mt-[1rem] cursor-pointer sm:w-[32px] sm:h-[32px] w-[20px] h-[20px]"
              onClick={() => setDownloadMenu(false)}
            />
          </div>
        </section>
      )}
    </header>
  );
};

export default Hero;
