import React from "react";

const Newsletter = () => {
  return (
    <section className="padding">
      <h2 className="text-deepRed italic text-center font-bold volkhov md:text-[46px] sm:text-[35px] text-[25px] md:mt-[50px] mt-[40px]">
        See Whats Next
      </h2>
      <p className=" text-center inter lg:text-[18px] text-[16px] mb-[20px]">
        Follow the latest ways we’re supporting and strengthening the people
        around us.
      </p>

      <div className="flex items-center flex-wrap gap-[20px] justify-center mb-[15px]">
        <input
          type="text"
          placeholder="Enter Email"
          className="border-b outline-0 bg-transparent border-secondary pb-[10px] inter sm:text-[18px] text-[16px] sm:w-[400px] w-full"
        />
        <button className="rounded-[50px] gap-[5px] lg:px-[20px] px-[15px] lg:py-[12px] py-[9px] cursor-pointer border text-lightRed_100 lg:text-[18px] text-[16px] inter leading-[30px]">
          Subscribe to our Newsletter
        </button>
      </div>

      <p className=" text-center inter lg:text-[18px] text-[16px] mb-[20px]">
        We promise not to spam you
      </p>
    </section>
  );
};

export default Newsletter;
