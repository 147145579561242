import React from "react";

const Header = () => {
  return (
    <section className="padding">
      <h1 className="text-center  mt-[3rem] volkhov md:text-[76px] sm:text-[50px] text-[35px] italic font-bold text-lightRed md:leading-[90px] leading-[60px] px-[20px] mb-[20px]">
        Putting People First
      </h1>
      <p className="text-gray text-center text-[18px] mb-[70px]">
        We are investing in the well-being of those we connect with, working
        hard toward a better future
      </p>

      <section className="flex md:flex-row flex-col items-center gap-[2rem] justify-center">
        <div>
          <p className="text-lightRed_100 lg:text-[24px] text-[20px] italic volkhov lg:mb-[20px] mb-[15px]">
            Inclusion & Diversity
          </p>

          <p className="max-w-[520px] inter lg:text-[18px] text-[16px] mb-[20px]">
            Our commitment to equal opportunity means cultivating an environment
            where differences are embraced and building a culture that fosters a
            sense of belonging.
          </p>
        </div>
        <img
          src="/images/career1.svg"
          alt="career"
          className="xl:w-[700px]  xl:h-[330px] lg:w-[500px] h-full md:w-[450px] w-full"
        />
      </section>
      <section className="flex md:flex-row flex-col-reverse items-center gap-[2rem] justify-center mt-[40px]">
        <img
          src="/images/career2.svg"
          alt="career"
          className="xl:w-[700px]  xl:h-[330px] lg:w-[500px] h-full md:w-[450px] w-full lg:ml-[-50px] ml-[0px]"
        />
        <div>
          <p className="text-lightRed_100 lg:text-[24px] text-[20px] italic volkhov lg:mb-[20px] mb-[15px]">
            Opportunities
          </p>

          <p className="max-w-[520px] inter lg:text-[18px] text-[16px] mb-[20px]">
            We put our partners(Employees) first, empowering them by providing
            meaningful opportunities to pursue their aspirations
          </p>
        </div>
      </section>

      <section className="flex md:flex-row flex-col items-center gap-[2rem] justify-center mt-[40px] mb-[30px]">
        <div>
          <p className="text-lightRed_100 lg:text-[24px] text-[20px] italic volkhov lg:mb-[20px] mb-[15px]">
            Opportunities
          </p>

          <p className="max-w-[520px] inter lg:text-[18px] text-[16px] mb-[20px]">
            Across the globe, we serve to strengthen each community we are part
            of by being their neighbor every neighborhood wants
          </p>
        </div>
        <img
          src="/images/career3.svg"
          alt="career"
          className="xl:w-[700px]  xl:h-[330px] lg:w-[500px] h-full md:w-[450px] w-full"
        />
      </section>
    </section>
  );
};

export default Header;
