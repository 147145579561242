import React, { useState, useEffect, useRef } from "react";

const Banner = ({ image, animate }) => {
  const [scale, setScale] = useState(1);
  const bannerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!bannerRef.current) return;
      const bannerOffsetTop = bannerRef.current.offsetTop;
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY;

      if (
        scrollPosition > bannerOffsetTop - windowHeight / 2 &&
        scrollPosition < bannerOffsetTop + windowHeight / 2
      ) {
        const newScale =
          1 +
          (scrollPosition - (bannerOffsetTop - windowHeight / 2)) /
            (windowHeight / 1);
        setScale(newScale);
      } else {
        setScale(1);
      }
    };

    window.addEventListener("scroll", handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {animate ? (
        <section className="overflow-hidden" ref={bannerRef}>
          <img
            style={{ transform: `scale(${scale})` }}
            src={image}
            loading="lazy"
            alt="banner-img"
            className="w-full lg:h-[313px] h-[200px] object-cover"
          />
        </section>
      ) : (
        <section className="overflow-hidden">
          <img
            loading="lazy"
            src={image}
            alt="banner-img"
            className="w-full lg:h-[313px] h-[200px] object-cover"
          />
        </section>
      )}
    </>
  );
};

export default Banner;
