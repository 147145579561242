import React from "react";

const Entertainment = () => {
  return (
    <section id="entertainment">
      <h2 className="text-deepRed italic text-center font-bold volkhov md:text-[46px] sm:text-[35px] text-[25px] md:mt-[100px] mt-[50px] ">
        Entertainment
      </h2>
      <div className="flex items-center justify-center flex-col">
        <p className="max-w-[730px] text-center text-warning sm:leading-[28px] leading-[20px] sm:text-[18px] text-[16px] mb-[1rem] px-[1rem]">
          Elevate your dining experience at Starbites, where our main
          restaurants transform into vibrant oases of relaxation and
          entertainment. Welcome to Starbites Bar & Lounge, where every sip is
          an adventure and every moment is a celebration.
        </p>
        <p className="max-w-[730px] text-center text-warning sm:leading-[28px] leading-[20px] sm:text-[18px] text-[16px] mb-[38px] px-[1rem]">
          At our main restaurants, we offer a wide range of engaging activities
          to enhance your visit. Enjoy Sip and Paint sessions, karaoke nights,
          live band performances, jazz evenings, game nights, and live DJ
          sessions, typically held on weekends and holidays. These activities
          add an extra layer of fun and excitement to your dining experience,
          making every visit to Starbites truly memorable.
        </p>
      </div>

      <div className="images mb-[7rem]">
        <div className="images__slide">
          <img src="/images/gallery1.png" alt="gallery__image" />
          <img src="/images/gallery2.png" alt="gallery__image" />
          <img src="/images/gallery3.png" alt="gallery__image" />
          <img src="/images/gallery4.png" alt="gallery__image" />
          <img src="/images/gallery1.png" alt="gallery__image" />
          <img src="/images/gallery2.png" alt="gallery__image" />
          <img src="/images/gallery3.png" alt="gallery__image" />
          <img src="/images/gallery4.png" alt="gallery__image" />
        </div>
      </div>
    </section>
  );
};

export default Entertainment;
