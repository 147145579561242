import React from "react";

const Macquee = () => {
  return (
    <section className="py-[2rem] animateText">
      <div className="flex sm:gap-[6rem] gap-[2rem] animateText-slide">
        <a
          className="text-deepRed font-bold md:text-[67px]  sm:text-[40px] text-[30px] volkhov italic cursor-pointer"
          href="#restaurant "
        >
          1. Restaurant
        </a>
        <a
          href="#bar"
          className="text-deepRed font-bold md:text-[67px]  sm:text-[40px] text-[30px] volkhov italic cursor-pointer"
        >
          2. Bar & Lounge
        </a>
        <a
          href="#entertainment"
          className="text-deepRed font-bold md:text-[67px]  sm:text-[40px] text-[30px] volkhov italic cursor-pointer"
        >
          3. Entertainment
        </a>
        <a
          href="#menu"
          className="text-deepRed font-bold md:text-[67px]  sm:text-[40px] text-[30px] volkhov italic cursor-pointer"
        >
          4. Menu
        </a>
      </div>

      <div className="flex sm:gap-[6rem] gap-[2rem]  animateText-slide">
        <a
          className="text-deepRed font-bold md:text-[67px]  sm:text-[40px] text-[30px] volkhov italic cursor-pointer"
          href="#restaurant "
        >
          1. Restaurant
        </a>
        <a
          href="#bar"
          className="text-deepRed font-bold md:text-[67px]  sm:text-[40px] text-[30px] volkhov italic cursor-pointer"
        >
          2. Bar & Lounge
        </a>
        <a
          href="#entertainment"
          className="text-deepRed font-bold md:text-[67px]  sm:text-[40px] text-[30px] volkhov italic cursor-pointer"
        >
          3. Entertainment
        </a>
        <a
          href="#menu"
          className="text-deepRed font-bold md:text-[67px]  sm:text-[40px] text-[30px] volkhov italic cursor-pointer"
        >
          4. Menu
        </a>
      </div>
      <div className="flex sm:gap-[6rem] gap-[2rem]  animateText-slide">
        <a
          className="text-deepRed font-bold md:text-[67px]  sm:text-[40px] text-[30px] volkhov italic cursor-pointer"
          href="#restaurant "
        >
          1. Restaurant
        </a>
        <a
          href="#bar"
          className="text-deepRed font-bold md:text-[67px]  sm:text-[40px] text-[30px] volkhov italic cursor-pointer"
        >
          2. Bar & Lounge
        </a>
        <a
          href="#entertainment"
          className="text-deepRed font-bold md:text-[67px]  sm:text-[40px] text-[30px] volkhov italic cursor-pointer"
        >
          3. Entertainment
        </a>
        <a
          href="#menu"
          className="text-deepRed font-bold md:text-[67px]  sm:text-[40px] text-[30px] volkhov italic cursor-pointer"
        >
          4. Menu
        </a>
      </div>
    </section>
  );
};

export default Macquee;
