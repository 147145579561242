import React, { useState, useEffect } from "react";

const ImageCarousel = () => {
  const [images, setImages] = useState([
    "/images/welcome1.png",
    "/images/welcome.svg",
    "/images/welcome3.svg",
    "/images/welcome-img.svg",
  ]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <img
      src={images[currentImageIndex]}
      alt={`Welcome Image ${currentImageIndex + 1}`}
      className="xl:w-[770px] xl:h-[725px] lg:w-[550px] lg:h-[600px] md:w-[450px] md:h-full w-full h-full object-cover"
    />
  );
};

export default ImageCarousel;
