import { useState } from 'react';
import { useSpring, animated } from 'react-spring';

const AccordionItem = ({ title, content, price, titleColor, priceColor }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { height, opacity } = useSpring({
    from: { height: 0, opacity: 1 },
    to: { height: isOpen ? 'auto' : 0, opacity: isOpen ? 1 : 1 },
  });

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className="borderAc w-[100%] mb-[20px]  cursor-pointer"
      onClick={toggleAccordion}
    >
      <div className="flex items-center justify-between ">
        <div className="flex items-center justify-between mb-[20px] w-[100%]">
          <p
            className={`md:text-[18px] sm:text-[16px] text-[15px] text-${titleColor} inter font-semibold `}
          >
            {title}
          </p>
          <p
            className={`md:text-[18px] sm:text-[16px] text-[15px] text-${priceColor} inter font-semibold`}
          >
            {price}
          </p>
        </div>
      </div>
      <animated.div
        style={{ height, opacity }}
        className="transition-all duration-300 "
      >
        <p
          className={
            isOpen
              ? 'text-warning inter md:text-[18px] sm:text-[16px] text-[15px] overflow-hidden leading-[25px] mb-[20px] mt-[-20px]'
              : 'text-warning inter text-[18px] overflow-hidden leading-[25px] mb-[20px] opacity-0'
          }
        >
          {content}
        </p>
      </animated.div>
    </div>
  );
};

export default AccordionItem;
