import React from "react";
import Header from "../components/Header";
import Newsletter from "../components/Newsletter";
import Banner from "../components/Banner";
import FooterCareer from "../components/FooterCareer";
import Navbar from "../components/Navbar";
const Career = () => {
  return (
    <section>
      <Navbar />
      <Header />
      <Banner image={"/images/footer-banner.svg"} />
      <Newsletter />
      <FooterCareer />
    </section>
  );
};

export default Career;
