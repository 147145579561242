import AccordionItem from "./AccordionItem";

const TodaysSpecialMenu = () => {
  return (
    <section className="padding ">
      <p className="text-lightGreen volkhov md:text-[18px] sm:text-[16px] text-[14px] italic leading-[30px] text-center md:mt-[120px] mt-[50px] mb-[13px]">
        The Chef recommends it.
      </p>
      <h2 className="text-deepRed text-center font-bold volkhov md:text-[46px] sm:text-[35px] italic text-[25px] sm:mb-[110px] mb-[50px]">
        Chef's Special
      </h2>
      <section className="flex items-center md:flex-row flex-col justify-between lg:gap-[100px] gap-[50px]">
        <div className="flex justify-center flex-col sm:gap-[60px] gap-[30px]">
          <div>
            <p className="text-lightGreen sm:mb-[32px] mb-[15px] volkhov sm:text-[24px] text-[21px] italic sm:text-start text-center">
              Breakfast
            </p>

            <div>
              <AccordionItem
                titleColor={"lightGreen"}
                priceColor={"lightRed_100"}
                title={"American Breakfast"}
                price={"GHS 20"}
                content={
                  "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                }
              />
              <AccordionItem
                titleColor={"lightGreen"}
                priceColor={"lightRed_100"}
                title={"Americano"}
                price={"GHS 20"}
                content={
                  "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                }
              />
              <AccordionItem
                titleColor={"lightGreen"}
                priceColor={"lightRed_100"}
                title={"Assorted Flavours"}
                price={"GHS 15"}
                content={
                  "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                }
              />
              <AccordionItem
                titleColor={"lightGreen"}
                priceColor={"lightRed_100"}
                title={"Breakfast Tea"}
                price={"GHS 20"}
                content={
                  "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                }
              />
            </div>
          </div>
          <div>
            <p className="text-lightGreen sm:mb-[32px] mb-[15px] volkhov sm:text-[24px] text-[21px] italic sm:text-start text-center">
              Wine & Beer
            </p>

            <AccordionItem
              titleColor={"lightGreen"}
              priceColor={"lightRed_100"}
              title={"American Breakfast"}
              price={"GHS 20"}
              content={
                "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
              }
            />

            <AccordionItem
              titleColor={"lightGreen"}
              priceColor={"lightRed_100"}
              title={"Assorted Flavours"}
              price={"GHS 15"}
              content={
                "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
              }
            />
          </div>
        </div>

        <img
          src="/images/food2.svg"
          alt=""
          className="md:w-[600px] w-full h-[500px] object-cover"
        />
      </section>

      <div className="flex items-center justify-end mt-[50px] mb-[-18px]">
        <img src="/images/flower-top.svg" alt="" />
      </div>
    </section>
  );
};

export default TodaysSpecialMenu;
