import axios from 'axios';
import moment from 'moment/moment';
import CryptoJS from 'crypto-js';

const appId = 'IPAYPOS';
const appKey = 'VbxjpMSiyZvH04idgL0fA7k3iwN48t3O';
const baseURL = 'https://manage.ipaygh.com/apidev/v1/gateway/';

export function appSecret(timeStamp) {
  const message = appId + ':' + timeStamp;
  return CryptoJS.HmacSHA512(message, appKey).toString();
}

export const Api = axios.create({
  baseURL,
});

Api.interceptors.request.use(
  async (request) => {
    let timestamp = moment().format('x');
    const secret = appSecret(timestamp);
    // const user = await retrievePersistedData('user');
    request.headers = {
      ...request.headers,
      Authentication: secret,
      Time: timestamp,
      Application: appId,
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    const formBody = [];
    for (let i in request.data) {
      const key = encodeURIComponent(i);
      const value = encodeURIComponent(request.data[i]);
      formBody.push(key + '=' + value);
    }
    request.data = formBody.join('&');
    return request;
  },
  (error) => Promise.reject(error)
);

