import { useState } from "react";

const Location = () => {
  const [location1, setLocation1] = useState(true);
  const [location2, setLocation2] = useState(false);
  const [location3, setLocation3] = useState(false);
  const [location4, setLocation4] = useState(false);
  const [location5, setLocation5] = useState(false);
  const [location6, setLocation6] = useState(false);
  const [location7, setLocation7] = useState(false);
  const [location8, setLocation8] = useState(false);
  const [location9, setLocation9] = useState(false);
  const [location10, setLocation10] = useState(false);
  const [location11, setLocation11] = useState(false);

  return (
    <section className="mb-[143px]">
      <h2 className="text-deepRed text-center volkhov md:text-[32px] text-[25px] mt-[107px]  font-bold italic">
        Get in touch
      </h2>

      <p className="text-deepRed volkhov md:text-[24px] text-[20px] mt-[20px] font-bold italic paddingLeft">
        Our Branch
      </p>

      <div className="flex md:flex-row flex-col md:justify-between justify-center gap-[54px] paddingLeft mt-[41px]">
        <div className="mb-[3.5rem]">
          <div className="flex items-center mb-[20px] gap-[5px]">
            <img src="/images/location.svg" alt="location__img" />
            <p className="text-tertiary inter text-[18px] font-semibold">
              Accra
            </p>
          </div>

          <div className="flex gap-[8px] items-center flex-wrap">
            <p
              className={`border border-gray_100 text-center px-[16px] py-[8px] rounded-[78px] opacity-[0.4] text-[14px] inter cursor-pointer ${
                location1 && "opacity-[1]"
              }`}
              onClick={() => {
                setLocation1(true);
                setLocation2(false);
                setLocation3(false);
                setLocation4(false);
                setLocation5(false);
                setLocation6(false);
                setLocation7(false);
                setLocation8(false);
                setLocation9(false);
                setLocation10(false);
                setLocation11(false);
              }}
            >
              Dzorwulu N1
            </p>
            <p
              className={`border border-gray_100 text-center px-[16px] py-[8px] rounded-[78px] opacity-[0.4] text-[14px] inter cursor-pointer ${
                location2 && "opacity-[1]"
              }`}
              onClick={() => {
                setLocation1(false);
                setLocation2(true);
                setLocation3(false);
                setLocation4(false);
                setLocation5(false);
                setLocation6(false);
                setLocation7(false);
                setLocation8(false);
                setLocation9(false);
                setLocation10(false);
                setLocation11(false);
              }}
            >
              West Legon
            </p>

            <p
              className={`border border-gray_100 text-center px-[16px] py-[8px] rounded-[78px] opacity-[0.4] text-[14px] inter cursor-pointer ${
                location3 && "opacity-[1]"
              }`}
              onClick={() => {
                setLocation1(false);
                setLocation2(false);
                setLocation3(true);
                setLocation4(false);
                setLocation5(false);
                setLocation6(false);
                setLocation7(false);
                setLocation8(false);
                setLocation9(false);
                setLocation10(false);
                setLocation11(false);
              }}
            >
              Tesano
            </p>
            <p
              className={`border border-gray_100 text-center px-[16px] py-[8px] rounded-[78px] opacity-[0.4] text-[14px] inter cursor-pointer ${
                location4 && "opacity-[1]"
              }`}
              onClick={() => {
                setLocation1(false);
                setLocation2(false);
                setLocation3(false);
                setLocation4(true);
                setLocation5(false);
                setLocation6(false);
                setLocation7(false);
                setLocation8(false);
                setLocation9(false);
                setLocation10(false);
                setLocation11(false);
              }}
            >
              Osu
            </p>
          </div>
          <div className="flex gap-[8px] items-center mt-[10px] flex-wrap">
            <p
              className={`border border-gray_100 text-center px-[16px] py-[8px] rounded-[78px] opacity-[0.4] text-[14px] inter cursor-pointer ${
                location5 && "opacity-[1]"
              }`}
              onClick={() => {
                setLocation1(false);
                setLocation2(false);
                setLocation3(false);
                setLocation4(false);
                setLocation5(true);
                setLocation6(false);
                setLocation7(false);
                setLocation8(false);
                setLocation9(false);
                setLocation10(false);
                setLocation11(false);
              }}
            >
              Dansoman
            </p>
            <p
              className={`border border-gray_100 text-center px-[16px] py-[8px] rounded-[78px] opacity-[0.4] text-[14px] inter cursor-pointer ${
                location6 && "opacity-[1]"
              }`}
              onClick={() => {
                setLocation1(false);
                setLocation2(false);
                setLocation3(false);
                setLocation4(false);
                setLocation5(false);
                setLocation6(true);
                setLocation7(false);
                setLocation8(false);
                setLocation9(false);
                setLocation10(false);
                setLocation11(false);
              }}
            >
              Ashaley Botwe
            </p>
            <p
              className={`border border-gray_100 text-center px-[16px] py-[8px] rounded-[78px] opacity-[0.4] text-[14px] inter cursor-pointer ${
                location7 && "opacity-[1]"
              }`}
              onClick={() => {
                setLocation1(false);
                setLocation2(false);
                setLocation3(false);
                setLocation4(false);
                setLocation5(false);
                setLocation6(false);
                setLocation7(true);
                setLocation8(false);
                setLocation9(false);
                setLocation10(false);
                setLocation11(false);
              }}
            >
              East Legon
            </p>
          </div>

          {/*  */}
          <div className="flex items-center mt-[50px] mb-[20px] gap-[5px]">
            <img src="/images/location.svg" alt="location__img" />
            <p className="text-tertiary inter text-[18px] font-semibold">
              Kumasi
            </p>
          </div>

          <div className="flex gap-[8px] items-center flex-wrap">
            <p
              className={`border border-gray_100 text-center px-[16px] py-[8px] rounded-[78px] opacity-[0.4] text-[14px] inter cursor-pointer ${
                location8 && "opacity-[1]"
              }`}
              onClick={() => {
                setLocation1(false);
                setLocation2(false);
                setLocation3(false);
                setLocation4(false);
                setLocation5(false);
                setLocation6(false);
                setLocation7(false);
                setLocation8(true);
                setLocation9(false);
                setLocation10(false);
                setLocation11(false);
              }}
            >
              Kentinkrono
            </p>
            <p
              className={`border border-gray_100 text-center px-[16px] py-[8px] rounded-[78px] opacity-[0.4] text-[14px] inter cursor-pointer ${
                location9 && "opacity-[1]"
              }`}
              onClick={() => {
                setLocation1(false);
                setLocation2(false);
                setLocation3(false);
                setLocation4(false);
                setLocation5(false);
                setLocation6(false);
                setLocation7(false);
                setLocation8(false);
                setLocation9(true);
                setLocation10(false);
                setLocation11(false);
              }}
            >
              Asokwa
            </p>
            <p
              className={`border border-gray_100 text-center px-[16px] py-[8px] rounded-[78px] opacity-[0.4] text-[14px] inter cursor-pointer ${
                location10 && "opacity-[1]"
              }`}
              onClick={() => {
                setLocation1(false);
                setLocation2(false);
                setLocation3(false);
                setLocation4(false);
                setLocation5(false);
                setLocation6(false);
                setLocation7(false);
                setLocation8(false);
                setLocation9(false);
                setLocation10(true);
                setLocation11(false);
              }}
            >
              Adum
            </p>
          </div>

          <div className="flex items-center mt-[50px] mb-[20px] gap-[5px]">
            <img src="/images/location.svg" alt="location__img" />
            <p className="text-tertiary inter text-[18px] font-semibold">
              Tema
            </p>
          </div>

          <div className="flex gap-[8px] items-center">
            <p
              className={`border border-gray_100 text-center px-[16px] py-[8px] rounded-[78px] opacity-[0.4] text-[14px] inter cursor-pointer ${
                location11 && "opacity-[1]"
              }`}
              onClick={() => {
                setLocation1(false);
                setLocation2(false);
                setLocation3(false);
                setLocation4(false);
                setLocation5(false);
                setLocation6(false);
                setLocation7(false);
                setLocation8(false);
                setLocation9(false);
                setLocation10(false);
                setLocation11(true);
              }}
            >
              Tema
            </p>
          </div>
        </div>

        <div className="md:w-[1000px] w-full h-[500px] relative mb-[70px]">
          {location1 && (
            <iframe
              width="100%"
              height="600"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Starbites%20Dzorwulu,%20N1,%20Accra+(Starbites)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.gps.ie/">gps vehicle tracker</a>
            </iframe>
          )}
          {location2 && (
            <iframe
              width="100%"
              height="600"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Starbites%20West%20Legon,%20Westlands%20Blvd,%20Accra+(Starbites)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.gps.ie/">gps trackers</a>
            </iframe>
          )}

          {location3 && (
            <iframe
              width="100%"
              height="600"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Starbites%20Tesano,%20Nsawam%20Rd,%20Accra+(Starbites)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.gps.ie/">gps trackers</a>
            </iframe>
          )}
          {location4 && (
            <iframe
              width="100%"
              height="600"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Starbites%20Osu,%20Castle%20Rd,%20Accra+(Starbites)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.gps.ie/">gps tracker sport</a>
            </iframe>
          )}

          {location5 && (
            <iframe
              width="100%"
              height="600"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Starbites%20Dansoman,%20Dansoman%20High%20St,%20Accra+(Starbites)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.gps.ie/">gps devices</a>
            </iframe>
          )}

          {location6 && (
            <iframe
              width="100%"
              height="600"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Ashaley%20Botwe%20Shell%20Fuel%20Station,%20Madina+(Starbites)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.gps.ie/">gps tracker sport</a>
            </iframe>
          )}
          {location7 && (
            <iframe
              width="100%"
              height="600"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Starbites%20East%20Legon,%202%20Jungle%20Ave,%20Accra+(Starbites)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.gps.ie/">gps vehicle tracker</a>
            </iframe>
          )}

          {location8 && (
            <iframe
              width="100%"
              height="600"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=89%20Accra%20Rd,%20Kumasi+(Starbites)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.gps.ie/">gps trackers</a>
            </iframe>
          )}

          {location9 && (
            <iframe
              width="100%"
              height="600"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Starbites%20Asokwa,%20Lake%20Rd,%20Kumasi+(Starbites)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.gps.ie/">gps systems</a>
            </iframe>
          )}

          {location10 && (
            <iframe
              width="100%"
              height="600"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Starbites%20Adum,%20103%20Happer%20Rd,%20Kumasi+(Starbites)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.gps.ie/">gps vehicle tracker</a>
            </iframe>
          )}

          {location11 && (
            <iframe
              width="100%"
              height="600"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Starbites%20Tema,%20Shell%20Fuel%20Station,%20Mitchell%20Rd,%20Tema+(Starbites)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.gps.ie/">gps trackers</a>
            </iframe>
          )}
          <h1 className="stroke_text volkhov xl:flex hidden">
            Get in <br /> touch
          </h1>
        </div>
      </div>

      <div className="flex justify-between md:flex-row flex-col md:items-end items-center mt-[50px]">
        <div className="paddingLeft">
          <p className="text-deepRed volkhov md:text-[24px] text-[20px] sm:mt-[20px] mt-[40px] mb-[32px] font-bold italic ">
            Opening Times
          </p>
          <section className="flex items-start lg:gap-[200px] md:gap-[100px] gap-[20px] sm:flex-row flex-col">
            <div className="flex flex-col gap-[5px]">
              <p className="text-tertiary inter text-[18px] font-semibold">
                Main Branch
              </p>
              <p className="text-tertiary inter text-[18px] font-semibold">
                Food to Go Branches
              </p>
              <p className="text-tertiary inter text-[18px] font-semibold">
                Express
              </p>
            </div>

            <div className="flex flex-col mt-[10px]">
              <p className="text-[18px] text-gray_100 inter">
                Weekdays 7:00 am- 11:00 pm
              </p>
              <p className="text-[18px] text-gray_100 inter">
                Weekdays 7:00 am- 11:00 pm
              </p>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default Location;
