import React from "react";
import AccordionItem from "./AccordionItem";

const Bar = () => {
  return (
    <section className="lg:px-[5rem] md:px-[2rem] px-[1rem] pb-[4rem]" id="bar">
      <h2 className="text-deepRed italic text-center font-bold volkhov md:text-[46px] sm:text-[35px] text-[25px] md:mt-[100px] mt-[60px] md:mb-[50px] mb-[30px] ">
        Bar & Lounge
      </h2>

      <div className="flex md:flex-row flex-col  justify-center items-center md:gap-[10rem] gap-[2rem]">
        <div className="">
          <p className="text-warning inter lg:text-[18px] text-[16px] lg:leading-[30px] leading-[25px]  max-w-[580px]">
            Elevate your dining experience at StarBites, where our restaurant
            transforms into a vibrant oasis of relaxation and entertainment.
            Welcome to StarBites Bar & Lounge, where every sip is an adventure
            and every moment is a celebration
          </p>

          <div className="lg:mt-[100px]  mt-[40px]">
            <p className="text-lightRed_100 sm:mb-[32px] mb-[15px] volkhov sm:text-[24px] text-[21px] font-bold">
              Menu
            </p>

            <div>
              <AccordionItem
                titleColor={"lightGreen"}
                priceColor={"lightRed_100"}
                title={"American Breakfast"}
                price={"GHS 95"}
                content={
                  "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                }
              />
              <AccordionItem
                titleColor={"lightGreen"}
                priceColor={"lightRed_100"}
                title={"Americano"}
                price={"GHS 20"}
                content={
                  "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                }
              />
              <AccordionItem
                titleColor={"lightGreen"}
                priceColor={"lightRed_100"}
                title={"Assorted Flavours"}
                price={"GHS 15"}
                content={
                  "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                }
              />
              <AccordionItem
                titleColor={"lightGreen"}
                priceColor={"lightRed_100"}
                title={"Breakfast Tea"}
                price={"GHS 20"}
                content={
                  "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                }
              />
            </div>
          </div>
        </div>

        <div>
          <img src="/images/bar1.png" alt="bar" loading="lazy" />
          <img
            src="/images/bar2.png"
            alt="bar"
            className="mt-[-150px] ml-[-120px] w-[270px] h-[256px]"
            loading="lazy"
          />
        </div>
      </div>
    </section>
  );
};

export default Bar;
