import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="padding bg-[#0D0000]" id="contact">
      <section className="flex gap-[2rem] flex-wrap sm:justify-between">
        <div>
          <p className="text-deepRed volkhov md:text-[24px] text-[20px] sm:mt-[20px] mt-[40px] mb-[24px] font-bold italic ">
            Starbites
          </p>

          <p className="text-gray_100 inter leading-[25px] text-[16px]">
            Starbites Food & Drink is an award winning restaurant, and{" "}
            <br className="sm:flex hidden" />
            food service with branches across Accra, Tema and
            <br className="sm:flex hidden" /> Kumasi.
          </p>
          <Link
            to={"/career"}
            className="flex items-center mt-[40px] border border-deepRed w-[150px] justify-center px-[24px] py-[12px] rounded-[53px] gap-[10px] cursor-pointer"
          >
            <img
              src="/images/star-icon.svg"
              alt="star-icon"
              className="w-[24px] h-[24px]"
            />
            <p className="text-deepRed text-[20px] font-semibold inter">
              Career
            </p>
          </Link>
        </div>
        <div className="xl:ml-[-200px] lg:ml-[-100px] ml-0">
          <p className="text-deepRed volkhov md:text-[24px] text-[20px] sm:mt-[20px] mt-[40px] mb-[32px] font-bold italic ">
            Contact
          </p>
          <p className="text-gray_100 inter leading-[25px] text-[16px]">
            Call our Call Center on
            <br className="sm:flex hidden" /> 024 243 6868/ 020 226 6200 or
            <br className="sm:flex hidden" /> send us an email
          </p>
          <p className="text-gray_100 inter leading-[25px] text-[16px]">
            info@starbitesgh.com
          </p>
        </div>
        <div>
          <p className="text-deepRed volkhov md:text-[24px] text-[20px] sm:mt-[20px] mt-[40px] mb-[32px] font-bold italic ">
            Follow us
          </p>

          <div className="flex gap-[1rem]">
            <Link
              to={"https://twitter.com/starbitesgh_?lang=en"}
              target="_blank"
            >
              <img
                src="/images/footer-icon1.svg"
                alt="icon"
                className="cursor-pointer w-[26px] h-[26px]"
              />
            </Link>
            <Link
              to={"https://www.instagram.com/starbitesgh/?hl=en"}
              target="_blank"
            >
              <img
                src="/images/footer-icon2.svg"
                alt="icon"
                className="cursor-pointer w-[26px] h-[26px]"
              />
            </Link>
            <Link
              to={
                "https://web.facebook.com/starbitesgh/?_rdc=1&_rdr&checkpoint_src=any"
              }
              target="_blank"
            >
              {" "}
              <img
                src="/images/footer-icon3.svg"
                alt="icon"
                className="cursor-pointer w-[26px] h-[26px]"
              />
            </Link>
            <Link to={"https://www.tiktok.com/@starbitesgh"} target="_blank">
              {" "}
              <img
                src="/tik.svg"
                alt="icon"
                className="cursor-pointer w-[26px] h-[26px]"
              />
            </Link>
          </div>
        </div>
      </section>

      <div className="mt-[49px] mb-[30px] h-[1px] w-full bg-tertiary"></div>

      <p className="text-gray_100 text-center inter text-[14px] mb-[1rem]">
        Powered by{" "}
        <Link
          className="text-lightRed cursor-pointer"
          to={"https://business.digistoreafrica.com"}
          target="_blank"
        >
          Digistore
        </Link>
      </p>
    </footer>
  );
};

export default Footer;
