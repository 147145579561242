import React from "react";
import { useState } from "react";
import AccordionItem from "./AccordionItem";

const Restaurant = () => {
  const [menu, setMenu] = useState(false);
  const [menu2, setMenu2] = useState(false);
  const [menuItem, setMenuItem] = useState(true);
  const [location, setLocation] = useState(false);
  const [isHovering1, setIsHovering1] = useState(false);
  const [isHovering2, setIsHovering2] = useState(false);

  return (
    <>
      <section className="paddingLeft" id="restaurant">
        <p className="text-warning inter lg:text-[18px] text-[16px] lg:leading-[30px] leading-[25px]  max-w-[630px] mb-[1rem] mt-[40px] xs:mt-[20px]">
          Starbites offers a dynamic dining experience across its 11 branches
          located in Accra, Tema, and Kumasi. Our restaurants are designed with
          three main concepts to cater to a variety of dining preferences:
          Food2Go, Express, and our Main restaurant.
        </p>
        <span className="text-deepRed italic font-bold volkhov md:text-[46px] sm:text-[35px] text-[25px] leading-[50px]">
          {" "}
          Restaurant
        </span>{" "}
        <p className="text-warning inter lg:text-[18px] text-[16px] lg:leading-[30px] leading-[25px]  max-w-[630px] mb-[1rem]">
          This concept caters to every palate, offering everything from full
          breakfasts to a wide selection of carefully curated lunch and dinner
          options, all infused with popular local cuisine. The first Starbites
          restaurant opened in East Legon, Accra, in 2011. Since then, the
          concept has expanded to multiple locations across Accra, Tema, and
          Kumasi. We currently operate five branches based on this concept, each
          with its own distinct and refined ambiance.
        </p>
        <p className="text-warning inter lg:text-[18px] text-[16px] lg:leading-[30px] leading-[25px]  max-w-[630px] mb-[1rem]">
          This quick-service restaurant focuses on a server-to-table style of
          service and accommodates various order types, including dine-in,
          takeout, and delivery.
        </p>
        <p className="text-warning inter lg:text-[18px] text-[16px] lg:leading-[30px] leading-[25px]  max-w-[630px] sm:mb-[8rem] mb-[3rem]">
          On weekends and holidays, each outlet offers live entertainment,
          enhancing the experience of enjoying delicious meals, engaging games,
          and lively music with friends and family in a comfortable and fun
          environment.
        </p>
        <div>
          <div className="flex flex-col gap-[20px] mb-[5rem] relative">
            <div>
              <h3
                className="text-secondary volkhov sm:text-[46px] text-[30px] italic font-bold  cursor-pointer hover:text-lightRed transition ease-in duration-200"
                onClick={() => setMenu(true)}
                onMouseEnter={() => setIsHovering1(true)}
                onMouseLeave={() => setIsHovering1(false)}
              >
                Food2Go
              </h3>
              <div className={"w-full h-[1px] bg-secondary "}></div>
            </div>

            <h3
              className="text-secondary volkhov sm:text-[46px] text-[30px] italic font-bold border-b border-secondary cursor-pointer hover:text-lightRed transition ease-in duration-200 "
              onClick={() => setMenu2(true)}
              onMouseEnter={() => setIsHovering2(true)}
              onMouseLeave={() => setIsHovering2(false)}
            >
              Express
            </h3>
          </div>
          {isHovering1 && (
            <img
              src="/images/hover1.png"
              alt="Food2Go"
              className="absolute lg:mt-[-28rem] sm:mt-[-24rem] mt-[-17rem] lg:right-[15rem] sm:right-[20px] right-[10px] lg:w-[558px] sm:w-[400px] w-[220px] lg:h-[386px] sm:h-[300px] h-[200px] object-cover"
              onMouseEnter={() => {
                setIsHovering1(true);
              }}
              onMouseLeave={() => {
                setIsHovering1(false);
              }}
            />
          )}

          {isHovering2 && (
            <div>
              <img
                src="/images/hover2.png"
                alt="Food2Go"
                className="absolute lg:mt-[-28rem] sm:mt-[-24rem] mt-[-17rem] lg:right-[15rem] sm:right-[20px] right-[10px] lg:w-[558px] sm:w-[400px] w-[220px] lg:h-[386px] sm:h-[300px] h-[200px] object-cover"
                onMouseEnter={() => {
                  setIsHovering2(true);
                }}
                onMouseLeave={() => {
                  setIsHovering2(false);
                }}
              />
            </div>
          )}
        </div>
        {menu && (
          <section className="fixed top-0 left-0 h-[100vh] w-full z-10 paddingLeft pop__up flex md:flex-row  flex-col justify-between   overflow-auto gap-[3rem] ">
            <div className="md:pr-[0rem] pr-[1rem]">
              <img
                src="/images/closeDark.svg"
                className="cursor-pointer mt-[50px]"
                alt=""
                onClick={() => setMenu(false)}
              />

              <h3
                className="text-lightRed_100 volkhov sm:text-[46px] text-[30px] italic font-bold cursor-pointer hover:text-lightRed transition ease-in duration-200 mt-[60px]"
                onClick={() => setMenu(true)}
              >
                Food2Go
              </h3>
              <p className="text-warning inter lg:text-[18px] text-[16px] lg:leading-[30px] leading-[25px]  max-w-[530px] mb-[1rem]">
                The Food2Go concept was established in 2020 in response to the
                need to provide some of our popular dishes to essential workers'
                offices and customers' homes during lockdowns. This led to the
                creation of two new outlets with a counter-service style and a
                live cooking area at the order points.
              </p>
              <p className="text-warning inter lg:text-[18px] text-[16px] lg:leading-[30px] leading-[25px]  max-w-[530px] sm:mb-[8rem] mb-[3rem]">
                A major source of inspiration for this concept is the speed of
                service and the convenience of an almost self-service
                experience, allowing customers to enjoy freshly prepared meals
                quickly. Key factors that set these outlets apart from others
                include lower overhead costs, a consistent menu available
                year-round, and a focus on in-house delivery service as well as
                partnerships with delivery aggregators.
              </p>

              <div
                className="flex justify-between cursor-pointer items-center border-b border-secondary pb-[15px]"
                onClick={() => setMenuItem((prev) => !prev)}
              >
                <p className="text-lightRed_100 font-bold inter text-[24px]">
                  Menu
                </p>
                {menuItem ? (
                  <img
                    loading="lazy"
                    src="/images/minus.svg"
                    alt="minus-icon"
                    className="w-[30px] h-[30px]"
                  />
                ) : (
                  <img
                    loading="lazy"
                    src="/images/plus.svg"
                    alt="plus-icon"
                    className="w-[30px] h-[30px]"
                  />
                )}
              </div>

              {menuItem ? (
                <div className="mt-[50px]">
                  <AccordionItem
                    titleColor={"lightGreen"}
                    priceColor={"lightRed_100"}
                    title={"American Breakfast"}
                    price={"GHS 95"}
                    content={
                      "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                    }
                  />
                  <AccordionItem
                    titleColor={"lightGreen"}
                    priceColor={"lightRed_100"}
                    title={"Americano"}
                    price={"GHS 20"}
                    content={
                      "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                    }
                  />
                  <AccordionItem
                    titleColor={"lightGreen"}
                    priceColor={"lightRed_100"}
                    title={"Assorted Flavours"}
                    price={"GHS 15"}
                    content={
                      "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                    }
                  />
                  <AccordionItem
                    titleColor={"lightGreen"}
                    priceColor={"lightRed_100"}
                    title={"Breakfast Tea"}
                    price={"GHS 20"}
                    content={
                      "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                    }
                  />
                </div>
              ) : (
                ""
              )}
              <div
                className="flex mt-[30px] justify-between cursor-pointer items-center border-b border-secondary pb-[15px]"
                onClick={() => setLocation((prev) => !prev)}
              >
                <p className="text-lightRed_100 font-bold inter text-[24px]">
                  Location
                </p>
                {location ? (
                  <img
                    loading="lazy"
                    src="/images/minus.svg"
                    alt="minus-icon"
                    className="w-[30px] h-[30px]"
                  />
                ) : (
                  <img
                    loading="lazy"
                    src="/images/plus.svg"
                    alt="plus-icon"
                    className="w-[30px] h-[30px]"
                  />
                )}
              </div>

              {location && (
                <div className="mt-[40px] pb-[4rem]">
                  <p className="text-warning inter text-[18px] mb-[10px]">
                    Ashale Botwe
                  </p>
                  <p className="text-warning inter text-[18px] mb-[10px]">
                    Tesano
                  </p>
                </div>
              )}
            </div>
            <img
              loading="lazy"
              src="/images/food2gohover-img.svg"
              alt="food-img"
              className="xl:w-[800px] object-cover xl:h-full w-[500px] h-[600px] my-auto"
            />
          </section>
        )}
        {/* Express */}
        {menu2 && (
          <section className="fixed top-0 left-0 h-full w-full z-10 paddingLeft pop__up flex md:flex-row  flex-col justify-between   overflow-auto gap-[3rem]">
            <div className="md:pr-[0rem] pr-[1rem] ">
              <img
                loading="lazy"
                src="/images/closeDark.svg"
                className="cursor-pointer mt-[50px]"
                alt=""
                onClick={() => setMenu2(false)}
              />

              <h3
                className="text-lightRed_100 volkhov sm:text-[46px] text-[30px] italic font-bold cursor-pointer hover:text-lightRed transition ease-in duration-200 mt-[60px]"
                onClick={() => setMenu2(true)}
              >
                Express
              </h3>
              <p className="text-warning inter lg:text-[18px] text-[16px] lg:leading-[30px] leading-[25px]  max-w-[530px] mb-[1rem]">
                Recognizing the increasing demand for quick service among the
                working class, who often seek a meal or beverage on the go with
                minimal delays, we developed the Express concept. The first
                outlet opened in Kumasi in 2015, and the concept has since
                expanded to include a total of four outlets: two in Kumasi and
                two in Accra.
              </p>
              <p className="text-warning inter lg:text-[18px] text-[16px] lg:leading-[30px] leading-[25px]  max-w-[530px]  mb-[1rem]">
                The Express concept offers ready-to-eat pre-packaged meals,
                salads, sandwiches, pastries, and hot and cold beverages.
                Without sacrificing quality, this concept delivers affordable
                meals and eliminates long wait times.
              </p>
              <p className="text-warning inter lg:text-[18px] text-[16px] lg:leading-[30px] leading-[25px]  max-w-[530px]  sm:mb-[5rem] mb-[3rem]">
                The outlets typically feature a small seating area where
                customers can wait comfortably while their meals or beverages
                are quickly heated, prepared, or packaged.
              </p>

              <div
                className="flex justify-between cursor-pointer items-center border-b border-secondary pb-[15px]"
                onClick={() => setMenuItem((prev) => !prev)}
              >
                <p className="text-lightRed_100 font-bold inter text-[24px]">
                  Menu
                </p>
                {menuItem ? (
                  <img
                    src="/images/minus.svg"
                    alt="minus-icon"
                    className="w-[30px] h-[30px]"
                  />
                ) : (
                  <img
                    src="/images/plus.svg"
                    alt="plus-icon"
                    className="w-[30px] h-[30px]"
                  />
                )}
              </div>

              {menuItem ? (
                <div className="mt-[50px]">
                  <AccordionItem
                    titleColor={"lightGreen"}
                    priceColor={"lightRed_100"}
                    title={"American Breakfast"}
                    price={"GHS 95"}
                    content={
                      "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                    }
                  />
                  <AccordionItem
                    titleColor={"lightGreen"}
                    priceColor={"lightRed_100"}
                    title={"Americano"}
                    price={"GHS 20"}
                    content={
                      "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                    }
                  />
                  <AccordionItem
                    titleColor={"lightGreen"}
                    priceColor={"lightRed_100"}
                    title={"Assorted Flavours"}
                    price={"GHS 15"}
                    content={
                      "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                    }
                  />
                  <AccordionItem
                    titleColor={"lightGreen"}
                    priceColor={"lightRed_100"}
                    title={"Breakfast Tea"}
                    price={"GHS 20"}
                    content={
                      "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                    }
                  />
                </div>
              ) : (
                ""
              )}
              <div
                className="flex mt-[30px] justify-between cursor-pointer items-center border-b border-secondary pb-[1rem]"
                onClick={() => setLocation((prev) => !prev)}
              >
                <p className="text-lightRed_100 font-bold inter text-[24px]">
                  Location
                </p>
                {location ? (
                  <img
                    src="/images/minus.svg"
                    alt="minus-icon"
                    className="w-[30px] h-[30px]"
                  />
                ) : (
                  <img
                    src="/images/plus.svg"
                    alt="plus-icon"
                    className="w-[30px] h-[30px]"
                  />
                )}
              </div>

              {location && (
                <div className="mt-[40px] pb-[4rem]">
                  <p className="text-warning inter text-[18px] mb-[10px]">
                    Ashale Botwe
                  </p>
                  <p className="text-warning inter text-[18px] mb-[3rem] ">
                    Tesano
                  </p>
                </div>
              )}
            </div>
            <img
              loading="lazy"
              src="/images/hover2.png"
              alt="food-img"
              className="xl:w-[800px] object-cover xl:h-full w-[600px] h-[600px] my-auto"
            />
          </section>
        )}
      </section>
    </>
  );
};

export default Restaurant;
