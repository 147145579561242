import React from 'react';
import {
  Banner,
  Bar,
  Entertainment,
  Footer,
  Hero,
  Location,
  Macquee,
  Menu,
  Restaurant,
  TodaysSpecialMenu,
  Welcome,
} from '../components';
import BannerStar from '../components/BannerStar';

const Home = () => {
  return (
    <main>
      <Hero />
      <Welcome />
      <Macquee />
      <Banner image={'/images/banner1.png'} />
      <Restaurant />
      <Banner image={'/images/banner2.png'} />
      <TodaysSpecialMenu />
      <Banner image={'/images/banner3.png'} />
      <Bar />
      <Banner image={'/images/banner4.png'} />
      <Entertainment />
      <Banner image={'/images/banner5.png'} />
      <Menu />
      <BannerStar image={'/images/starbite-banner.svg'} />
      <Location />
      <Banner image={'/images/banner7.png'} />
      <Footer />
    </main>
  );
};

export default Home;
