import React from "react";
import ImageCarousel from "./ImageSlider";

const Welcome = () => {
  return (
    <section
      className="mt-[100vh] flex justify-between items-center gap-[1rem] flex-col md:flex-row"
      id="about"
    >
      <div className="paddingLeft">
        <p className="text-lightGreen volkhov mt-[3rem] lg:text-[18px] sm:text-[16px] text-[14px] italic leading-[30px]">
          Welcome to Starbites
        </p>
        <h2 className="volkhov lg:text-[46px] sm:text-[33px] text-[30px] italic text-deepRed font-bold lg:leading-[55px] sm:leading-[40px] leading-[35px]">
          Akwaaba! Dive into flavours <br className="hidden xl:flex" />
          and create lasting memories
        </h2>

        <div className="max-w-[630px]">
          <p className="sm:mt-[30px] mt-[10px] text-primary inter sm:leading-[30px] leading-[25px] lg:text-[18px] sm:text-[16px] text-[15px]">
            Greetings from Starbites!
          </p>
          <p className="sm:mt-[20px] mt-[10px] text-primary inter sm:leading-[30px] leading-[25px] lg:text-[18px] sm:text-[16px] text-[15px]">
            Akwaaba! Immerse yourself in a world of flavors and unforgettable
            experiences. Starbites®, a proudly Ghanaian brand, welcomed guests
            to its first restaurant in East Legon, Accra, in 2011. Since then,
            it has been offering an array of dishes for Ghana's increasingly
            diverse and multicultural community, satisfying a wide range of
            tastes.
          </p>
          <p className="sm:mt-[20px] mt-[5px] text-primary inter sm:leading-[30px] leading-[25px] lg:text-[18px] sm:text-[16px] text-[15px]">
            Over the years, Starbites® has expanded and established itself as a
            unique Ghanaian brand, opening additional locations in the capital
            and across the country.
          </p>
        </div>
      </div>
      <ImageCarousel />
    </section>
  );
};

export default Welcome;
